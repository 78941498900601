gsap.registerPlugin(CSSRulePlugin, Draggable, EasePack, PixiPlugin, EaselPlugin, ScrollToPlugin, CustomEase, ScrollTrigger, InertiaPlugin, );


'use strict';
var EMA = EMA || {};
var scrollInstance;
EMA.BUILD = (function() {
    return {
        init: function() {
            // console.log("init");
            // Find all controller in page
            // Define template or content
            $('[data-control]').each(function(index, elem) {
                var data = $(elem).data(),
                    control = data.control.split(',');

                for (var i = 0; i < control.length; i++) {
                    var controller = control[i];
                    if (!EMA[controller]) {
                        return;
                    }

                    if (typeof EMA[controller] === 'function') {
                        var obj = new EMA[controller]();
                        obj.init(elem, data);
                    } else if (typeof EMA[controller] === 'object') {
                        EMA[controller].init(elem, data);
                    }
                }
            });

            // Convert IMG to SVG source code inline
            this.svg('img.svg-convert');

            // Load Lazy Images with animation
            this.lazyLoader();
            const pageContainer = document.querySelector(".scroll-container");

            inView(".b-lazy")
                .on('enter', el => {

                    if ($(el).hasClass("bg")) {
                        let bgImg = $(el).find("img").attr("src");
                        // console.log("bgImg",bgImg)
                        $(el).css("backgroundImage", "url('" + bgImg + "')");
                        $(el).addClass("b-loaded");
                    }
                    $(el).addClass("b-loaded");
                    // console.log("carregou");
                    new ResizeObserver(() => scrollInstance.update()).observe(pageContainer);
                }).on('exit', el => {
                    $(el).removeClass("b-loaded");
                });

            inView('.owl-carousel')
                .on('enter', el => {
                    let owl = $(el);

                    owl.trigger('play.owl.autoplay', [4000])
                }).on('exit', el => {
                    let owl = $(el);
                    owl.trigger('stop.owl.autoplay')
                });

            if (!isTouchScreendevice()) {

                pageContainer.setAttribute("data-scroll-container", "");
                $(pageContainer).append('<div class="phantom-footer"></div>');

                scrollInstance = new LocomotiveScroll({
                    el: pageContainer,
                    smooth: true,
                    getDirection: true
                        // scrollFromAnywhere: true
                        // ,
                        // smartphone: {
                        //     smooth: true
                        // }
                });

                // scroll.on("scroll", );
                scrollInstance.on("scroll", function(t) {
                    document.documentElement.setAttribute("data-direction", t.direction);
                });
                scrollInstance.on('scroll', (obj) => {
                    ScrollTrigger.update();
                });

                let horizontalSections = document.querySelectorAll(".horizontal-scroll:not(.is-draggable)");

                horizontalSections.forEach((horizontalSection) => {
                    let pinWrap = horizontalSection.querySelector(".pin-wrap");
                    let pinWrapWidth = pinWrap.offsetWidth;
                    let horizontalScrollLength = pinWrapWidth - window.innerWidth;
                    let progressEl = $(horizontalSection.querySelector(".progress-bar-container"));
                    gsap.to(pinWrap, {
                        scrollTrigger: {
                            scroller: "[data-scroll-container]",
                            scrub: true,
                            trigger: horizontalSection,
                            pin: true,
                            start: "top top",
                            end: () => `+=${pinWrapWidth}`,
                            invalidateOnRefresh: true,
                            onUpdate: function(e) {
                                var progress = Math.ceil(e.progress * 100);
                                var total = $(e.pin).find("img").length;

                                gsap.to(progressEl.find(".progress-bar-current"), .3, { width: percentToPixel(progressEl.find(".progress-bar-current"), progress) })
                                    // progressEl.find(".progress-bar-current").css("width", percentToPixel(progressEl.find(".progress-bar-current"), progress))
                                let currentY = (25 * (Math.floor(progress) * total) / 100) - 25;
                                if (currentY < 0) {
                                    currentY = 0;
                                }
                                // console.log(total, progress, (progress * total) / 100, currentY)
                                gsap.to(progressEl.find(".progress-index li"), 1, { y: -currentY, });
                                // progressEl.find(".progress-index div").css("transform", "translateY("+(-currentY)+"px)");
                            }
                        },
                        x: -horizontalScrollLength,
                        ease: "none"
                    });
                });

                ScrollTrigger.addEventListener("refresh", () => scrollInstance.update());
                ScrollTrigger.refresh();

                // ScrollTrigger.refresh();

            }
            new ResizeObserver(() => scrollInstance.update()).observe(pageContainer);


        },
        templateEngine: function(html, options) {

            // Git : https://github.com/krasimir/absurd/blob/master/lib/processors/html/helpers/TemplateEngine.js

            var re = /<%(.+?)%>/g,
                reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g,
                code = 'with(obj) { var r=[];\n',
                cursor = 0,
                result, match;

            var add = function(line, js) {
                js ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n') : (code += line !== '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
                return add;
            };

            while (match = re.exec(html)) {
                add(html.slice(cursor, match.index))(match[1], true);
                cursor = match.index + match[0].length;
            }

            add(html.substr(cursor, html.length - cursor));
            code = (code + 'return r.join(""); }').replace(/[\r\t\n]/g, ' ');

            try {
                result = new Function('obj', code).apply(options, [options]);
            } catch (err) {
                // console.error("'" + err.message + "'", ' in \n\nCode:\n', code, '\n');
            }

            return result;
        },
        lazyLoader: function() {

            var bLazy = new Blazy({
                offset: -100,
                success: function(element) {
                    setTimeout(function() {
                        // We want to remove the loader gif now.
                        // First we find the parent container
                        // then we remove the "loading" class which holds the loader image
                        var parent = element.parentNode;
                        parent.className = parent.className.replace(/\bloading\b/, '');
                    }, 10);
                }
            });

            return bLazy;
        },
        svg: function(elem) {
            $(elem).each(function() {
                var $img = $(this),
                    imgID = $img.attr('id'),
                    imgClass = $img.attr('class'),
                    imgURL = $img.attr('src'),
                    imgWidth = $img.attr('width'),
                    imgHeight = $img.attr('height');

                $.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgWidth !== 'undefined') {
                        $svg = $svg.css('width', imgWidth);
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgHeight !== 'undefined') {
                        $svg = $svg.css('height', imgHeight);
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Replace image with new SVG
                    $img.replaceWith($svg);

                }, 'xml');

            });
        },
        viewportOfElement: function(el) {
            var top = el.offsetTop;
            var left = el.offsetLeft;
            var width = el.offsetWidth;
            var height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        },
        resize: function(offset, element) {
            var scrollTop = Math.round($(window).scrollTop()),
                windowHeight = Math.round($(window).height()),
                windowWidth = Math.round($(window).width()),
                documentHeight = Math.round($(document).height()),
                elementTop = (element) ? Math.round($(element).offset().top) : null,
                elementHeight = (element) ? Math.round($(element).outerHeight(true)) : null,
                elementOffset = (elementTop) ? elementTop - Math.round(offset) : null,
                offsetDocument = (offset) ? documentHeight - Math.round(offset) : documentHeight,
                elementScrollBottom = (element) ? ((elementTop - scrollTop) + elementHeight) : null;

            return {
                scrollTop: scrollTop,
                windowHeight: windowHeight,
                windowWidth: windowWidth,
                documentHeight: documentHeight,
                ifBottom: scrollTop + windowHeight >= offsetDocument,
                elementTop: elementTop,
                elementOffset: elementOffset,
                elementHeight: elementHeight,
                elementScrollBottom: elementScrollBottom,
                elementTouchBottom: elementScrollBottom > windowHeight
            };
        }
    };
})();

function isTouchScreendevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints;
};
EMA.SCROLL_TOP = (function() {
    return {
        init: function(elem) {
            // hide #back-top first
            // $(elem).hide();

            // fade in #back-top
            // $(window).on('scroll', function() {
            //     if ($(this).scrollTop() > 100) {
            //         $(elem).fadeIn();
            //     } else {
            //         $(elem).fadeOut();
            //     }
            // });

            // scroll body to 0px on click
            $(elem).on('click', function() {
                $('body,html').animate({
                    scrollTop: 0
                }, 800);

                return false;
            });
        },
    };
})();
EMA.SEARCH = (function() {

    return {
        init: function(element) {

            $(element).materialize_autocomplete({
                limit: 10,
                hidden: {
                    enable: false
                },
                multiple: {
                    enable: false
                },
                ignoreCase: false,
                dropdown: {
                    el: '',
                    tagName: 'ul',
                    className: 'autocomplete-content dropdown-content',
                    itemTemplate: '<li data-id="<%= item.id %>" data-text="<%= item.text %>"><a href="/<%= item.deeplink %>"><span><%= item.highlight %></span></a></li>',
                    noItem: ''
                },
                onSelect: function(object, list) {

                    // var element = getSelector(this.$el),
                    //     user = element.user,
                    //     item = element.item,
                    //     data = list.data;

                    // console.log(object, list);


                },
                getData: function(value, callback, item) {


                    $.ajax({
                        url: '/frontend/engine/engine.php',
                        type: 'POST',
                        dataType: 'json',
                        data: $.param({
                            'action': 'searchFormated',
                            'keyword': value
                        }),
                        error: function(e) {
                            // console.error('error: ', e);
                        },
                        success: function(response) {

                            // Return Object for Autocomplete
                            callback(value, response);

                        }
                    });

                    return false;
                }
            });
        }
    };
})();

EMA.MAINNAV = (function() {
    var self,
        el,
        isActive,
        template,
        structure;
    return {
        init: function(elem, data) {
            self = this;
            el = $(elem);

            $(elem).find("li").each(function() {

                $(this).mouseenter(function(event) {
                    self.openDropdown($(this).find(".dropdown"));
                });
                $(this).click(function(event) {
                    self.openDropdown($(this).find(".dropdown"));
                });
                $(this).mouseleave(function(event) {
                    self.closeDropdown($(this).find(".dropdown"));
                });


            });

            $(window).click(function() {
                self.closeDropdown($(elem));
            });




        },
        closeDropdown: function(el) {
            var dropdownEl = el;
            console.log(el)
            dropdownEl.removeClass("active");
        },
        openDropdown: function(el) {
            var dropdownEl = el;
            console.log(dropdownEl)
            dropdownEl.addClass("active");

        }
    };
})();

EMA.HOME = (function() {
    var self,
        el;
    return {
        init: function(elem, data) {
            self = this;
            el = $(elem);
            $(window).resize(function() {
                self.resize();
            });
            self.resize();

            $('.owl-carousel').owlCarousel({
                margin: 0,
                loop: true,
                nav: true,
                items: 1,
                lazyLoad: true,
                autoplay: true,
                smartSpeed: 4000,
                autoplayTimeout: 4000,
                animateOut: 'fadeOut',

                mouseDrag: false,
                touchDrag: false
            });
            // $('.owl-carousel .owl-nav').each(function(event) {
            //     $(this).removeClass('disabled');
            // });
            $('.owl-carousel').on('mouseenter', '.owl-dots, .owl-nav', function(e) {
                $(this).closest('.owl-carousel').trigger('stop.owl.autoplay');
            });

            $('.owl-carousel').on('mouseleave', '.owl-dots, .owl-nav', function(e) {
                $(this).closest('.owl-carousel').trigger('play.owl.autoplay');
            });

        },
        resize: function() {
            // $(".main-nav").width($(window).height());
            // $(".main-nav").css("left", $(window).width()-$(".main-nav").height());

            var newHeight;
            var newMargin = 0;
            if (Foundation.MediaQuery.is('small down')) {
                newMargin = 10;
            }
            newHeight = Math.round($(window).height() - $("#header").outerHeight() - newMargin);
            // console.log(newHeight);

            var isPortrait = window.matchMedia('(orientation: portrait)').matches;



            if (isPortrait) {

                $(".courses-grid").removeClass("small-up-3");
                $(".courses-grid").addClass("small-up-2");
                $('.full-height, .full-height .row, .full-height .row .columns, .full-height.row, .full-height.row .columns').each(function() {


                    $(this).height(newHeight);
                    $(this).css("max-height", newHeight);
                    // $(this).find('img:not(.overlay-transparent-image').css("visibility", "hidden");
                    // $(this).find('.owl-item img').css("visibility", "visible");
                })
            } else {
                $(".courses-grid").removeClass("small-up-2");
                $(".courses-grid").addClass("small-up-3");

                $('.full-height, .full-height .row, .full-height .row .columns, .full-height.row, .full-height.row .columns').each(function() {
                    $(this).height("");
                    $(this).css("max-height", "auto");
                })
                if ('ontouchstart' in window) {
                    // The device supports touch events
                } else {
                    if (Foundation.MediaQuery.is('small up')) {
                        // console.log("aqui")
                        $('.full-height, .full-height .row, .full-height .row .columns, .full-height.row, .full-height.row .columns').each(function() {


                            $(this).height(newHeight);
                            $(this).css("max-height", newHeight);
                            // $(this).find('img:not(.overlay-transparent-image').css("visibility", "hidden");
                            // $(this).find('.owl-item img').css("visibility", "visible");
                        })
                    }
                }

            }




            $("#footer").css("bottom", 0);



        }
    }
})();
EMA.TEAM = (function() {

    return {
        init: function(elem) {
            // var autoplay = true;
            // if (Foundation.MediaQuery.current == 'small') {
            //     autoplay = false                
            // }

            // $('.owl-carousel').owlCarousel({
            //     margin:0,
            //     loop:true,
            //     nav: true,
            //     lazyLoad: true,
            //     autoplay: autoplay,
            //     smartSpeed: 1200,
            //     autoplayTimeout: 4000,
            //     animateOut: 'fadeOut',
            //     items:1,
            //     mouseDrag: false,
            //     autoHeight: true,
            //     autoplayHoverPause: true
            // });
            // $('.owl-carousel').on('mouseenter', '.owl-dots, .owl-nav', function(e) {
            //     $(this).closest('.owl-carousel').trigger('stop.owl.autoplay');
            // });

            // $('.owl-carousel').on('mouseleave', '.owl-dots, .owl-nav', function(e) {
            //     $(this).closest('.owl-carousel').trigger('play.owl.autoplay');
            // });
            var overlay = $(".team-detail");
            $(elem).find(".card").on("click", function() {

                let _title = $(this).data("title");
                let _subtitle = $(this).data("subtitle");
                let _body = $(this).data("body");

                overlay.find("h3").html(_title);
                overlay.find("h2").html(_subtitle);
                overlay.find(".team-body").html(_body);

                // console.log(_title, _subtitle, _body);
            })
        }
    };
})();
EMA.STUDENT_FORM = (function() {

    return {
        init: function(elem) {

            $(elem).on('formvalid.zf.abide', function(event, form) {

                var allInputs = form.serializeArray();

                for (var i = 0; i < allInputs.length; i++) {

                    var data = $(form).find('[name=' + allInputs[i].name + ']');

                    if (data.data('info') === undefined) {
                        allInputs[i].info = allInputs[i].name;
                    } else {
                        allInputs[i].info = data.data('info');
                    }
                }

                $.post('/frontend/engine/engine.php', {
                    'action': 'sendEmailByObject',
                    'body': allInputs
                }, function(data, status, xhr) {
                    if (data) {
                        $(elem).find('.msg').text(data.label);
                    } else {
                        // console.error(status, xhr);
                    }
                }, 'json').fail(function(xhr, textStatus, errorThrown) {
                    // console.error(xhr, textStatus, errorThrown);
                });

            }).on('submit', function(event) {
                event.preventDefault();
            });
        }
    };
})();

EMA.NEWSLETTER_FORM = (function() {
    return {
        init: function(elem) {
            var REGISTERALREADYREGISTERED = $(elem).data("label400");
            var NEWSLETTERSUCCESS = $(elem).data("label-success")
            $(elem).on('formvalid.zf.abide', function(event, form) {
                var email = form.serializeArray();
                // console.log(email);
                $.post('/frontend/engine/engine.php', {
                    'action': 'mailchimpSubscription',
                    'email': email[0].value,
                    'mailChimpListID': email[1].value

                }, function(data, status, xhr) {
                    // console.log(data);
                    if (data) {

                        if (data.status == 400) {
                            $(elem).find('.msg').text(REGISTERALREADYREGISTERED);
                        }
                        if (data.status == "subscribed") {
                            $(elem).find('.msg').text(NEWSLETTERSUCCESS);
                        }

                    } else {
                        // console.error(status, xhr);
                    }
                }, 'json').fail(function(xhr, textStatus, errorThrown) {
                    // console.error(xhr, textStatus, errorThrown);
                });
            }).on('submit', function(event) {
                event.preventDefault();
            });
        }
    };
})();

EMA.CONTACT_FORM = (function() {
    return {
        init: function(elem) {


            $(elem)
                .on('formvalid.zf.abide', function(event, form) {
                    $(this).find('.submit').fadeOut();
                    var mailChimpEmail = form.serializeArray();
                    // console.log(mailChimpEmail[1].value, mailChimpEmail[6].value);
                    $.post('/frontend/engine/engine.php', {
                        'action': 'mailchimpSubscription',
                        'email': mailChimpEmail[1].value,
                        'mailChimpListID': mailChimpEmail[6].value

                    });
                    $.post('/frontend/engine/engine.php', {
                        'action': 'sendEmailByObject',
                        'body': form.serializeArray()
                    }, function(data, status, xhr) {
                        if (data) {

                            $(elem).find('.msg').text(data.label);

                            fbq('track', 'Lead', {
                                'email': mailChimpEmail[1].value,
                                'course': mailChimpEmail[5].value
                            });
                            if (data.kind == "success") {
                                if ($('.contact-form').hasClass('has-brochure')) {
                                    // console.log(data);
                                    $('.contact-form').delay(2000).fadeOut(function() {
                                        $('.brochure-download, .brochure-download span').fadeTo(1000, 1);
                                        $('.brochure-download').attr('data-email', $('.contact-form input[name="email"]').val());
                                        var linkDownload = $('.brochure-download').data('downloadurl') + "?file=" + $('.brochure-download').data('file') + "&userID=" + data.data + "&contentUID=" + $('.brochure-download').data('contentuid');
                                        $('.brochure-download').attr('href', linkDownload);
                                        //console.log(linkDownload);
                                        window.location.href = linkDownload;
                                    })
                                }
                            } else {
                                $(this).find('.submit').fadeIn();
                            }
                        } else {
                            // console.error(status, xhr);
                        }
                    }, 'json').fail(function(xhr, textStatus, errorThrown) {
                        // console.error(xhr, textStatus, errorThrown);
                    });

                })
                .on('submit', function(event) {
                    event.preventDefault();
                });

        }
    };
})();
EMA.COURSE_WIDE = (function() {
    return {
        init: function(elem) {

            var owl = $('.owl-carousel').owlCarousel({
                margin: 0,
                loop: true,
                nav: true,
                autoplay: true,
                smartSpeed: 4000,
                autoplayTimeout: 4000,
                animateOut: 'fadeOut',
                items: 1,
                mouseDrag: false,
                autoHeight: true,
                autoplayHoverPause: false,
                video: true,
                lazyLoad: false,
                touchDrag: false

            });

            owl.on('changed.owl.carousel', function(e) {
                owl.trigger('stop.owl.autoplay');
                owl.trigger('play.owl.autoplay');
            });
        }
    };
})();
EMA.COURSE = (function() {
    return {
        init: function(elem) {

            var owl = $('.owl-carousel').owlCarousel({
                margin: 0,
                loop: true,
                nav: false,
                lazyLoad: true,
                autoplay: true,
                smartSpeed: 1200,
                autoplayTimeout: 4000,
                animateOut: 'fadeOut',
                items: 1,
                mouseDrag: false,
                autoHeight: true,
                touchDrag: false
            });
            owl.on('changed.owl.carousel', function(e) {
                owl.trigger('stop.owl.autoplay');
                owl.trigger('play.owl.autoplay');
            });
            // $('.owl-carousel').on('mouseenter', '.owl-dots, .owl-nav', function(e) {
            //     $(this).closest('.owl-carousel').trigger('stop.owl.autoplay');
            // });

            // $('.owl-carousel').on('mouseleave', '.owl-dots, .owl-nav', function(e) {
            //     $(this).closest('.owl-carousel').trigger('play.owl.autoplay');
            // });

            if (Foundation.MediaQuery.current == 'small') {
                $('.info-tag-container').insertAfter('#course-content-inside');

            } else {
                $('.info-tag-container').insertBefore('#course-content');

            }

            $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
                // console.log(Foundation.MediaQuery.current);
                if (Foundation.MediaQuery.current == 'small' || Foundation.MediaQuery.current == 'medium') {
                    $('.info-tag-container').insertAfter('#course-content-inside');
                } else {
                    $('.info-tag-container').insertBefore('#course-content');
                }
            });
            var sticky = new Sticky('.sticky-course-info');
            if (Foundation.MediaQuery.is('small down')) {
                sticky.destroy();
            } else {
                var sticky = new Sticky('.sticky-course-info');
            }

        }
    };
})();
EMA.FILTER = (function() {

    return {
        init: function(elem) {

            $(elem).on("click", function(e) {
                if ($(this).hasClass("active")) {
                    $(this).removeClass("active")
                } else {
                    $(this).addClass("active")
                }

                EMA.ONLINE_COURSE.filter();
            })
        }
    };
})();

EMA.ONLINE_COURSE = (function() {
    var filters;
    var $grid;
    return {
        init: function(elem) {
            filters = new Array();

            $grid = $('.courses-container').isotope({});
            $(".see-all").on("click", function() {
                $(".online-courses-filter a").removeClass("active");
                $grid.isotope({ filter: "" });
            })
        },
        filter: function() {
            // console.log("filter")
            filters = new Array();
            $(".online-courses-filter a").each(function(i) {
                    if ($(this).hasClass("active")) {
                        filters.push("." + $(this).data("filter"));
                    }
                    $grid.isotope({ filter: filters.toString() });
                })
                // console.log(filters.toString());
        }
    };
})();

EMA.HEART_FAVORITE = (function() {
    return {
        init: function(elem) {

            var animated = false;
            $(elem).on("click", function() {
                if (!animated) {
                    $(this).addClass('happy').removeClass('broken');
                    animated = true;
                } else {
                    $(this).removeClass('happy').addClass('broken');
                    animated = false;
                }
            });
        }
    };
})();

EMA.TEAM_STRIPE = (function() {
    return {
        init: function(elem) {

            // TEAM Slider
            var owl = $('.owl-carousel').owlCarousel({
                margin: 0,
                loop: true,
                nav: true,
                lazyLoad: true,
                autoplay: true,
                smartSpeed: 1200,
                autoplayTimeout: 4000,
                items: 3,
                mouseDrag: true,
                autoHeight: true,
                autoplayHoverPause: true,
                video: true,
                lazyLoad: true,
                touchDrag: false,
                responsive: {

                    0: {
                        items: 1
                    },
                    480: {
                        items: 1
                    },

                    1024: {
                        items: 2
                    },
                    1240: {
                        items: 3
                    }
                }
            });
            owl.on('changed.owl.carousel', function(e) {
                owl.trigger('stop.owl.autoplay');
                owl.trigger('play.owl.autoplay');
            });
            $('.owl-carousel').on('mouseenter', '.owl-dots, .owl-nav', function(e) {
                $(this).closest('.owl-carousel').trigger('stop.owl.autoplay');
            });

            $('.owl-carousel').on('mouseleave', '.owl-dots, .owl-nav', function(e) {
                $(this).closest('.owl-carousel').trigger('play.owl.autoplay');
            });
        }
    };
})();
EMA.STICKY = (function() {
    return {
        init: function(elem) {
            let _class = "." + $(elem).attr('class').split(' ')[0];
            var sticky = new Sticky(_class);
            if (Foundation.MediaQuery.is('small down')) {
                sticky.destroy();
            } else {
                var sticky = new Sticky(_class);
            }
        }
    };
})();


EMA.LOGIN = (function(el) {
    var _this;
    var _el;
    var _type;
    return {
        init: function(el) {
            _this = this;
            _el = el;
            //   console.log("login",el);
        }
    }
})();

EMA.FILTER_FORM = (function() {

    var _textarea;
    var _saveExportSettings;
    return {
        init: function(elem) {
            _textarea = $(elem).find("textarea");
            _saveExportSettings = $(elem).find("#saveExportSettings");

            var ugly = document.getElementById('filterTextarea').value;
            var obj = JSON.parse(ugly);
            var pretty = JSON.stringify(obj, undefined, 4);
            document.getElementById('filterTextarea').value = pretty;
            $(elem)
                .on('formvalid.zf.abide', function(event, form) {
                    // console.log(JSON.parse(_textarea.val()));
                    $.post('/frontend/engine/engine.php', {
                        'action': 'editContent',
                        'content': { "optional1": JSON.parse(_textarea.val()) }
                    }, function(data, status, xhr) {
                        // console.log(data);

                    });

                })
                .on('submit', function(event) {
                    event.preventDefault();
                });

        }
    };
})();

EMA.LIST_EXPORTER = (function() {
    var _fileName;
    var _textarea;
    return {
        init: function(elem) {
            _fileName = $(elem).find("input");
            _textarea = $(elem).find("textarea");

            let _date = "[" + formatDate("dd-MM-yyyy") + "]";
            // console.log(_date, _fileName);
            _fileName.val(_date);

            $(elem).find("select").on("change", function() {
                var cur_value = $('option:selected', this).toArray().map(item => item.text);

                // console.log(cur_value, );
                let _date = "[" + formatDate("dd-MM-yyyy") + "]";
                _fileName.val(cur_value + _date)

            })
        }
    };
})();
EMA.MOBILE_MENU = (function() {

    return {
        init: function(elem) {

            // console.log("MOBILE_MENU");
            $(elem).find(".menu li a").on("click", function(e) {

                if (!$(this).attr("href")) {
                    $(elem).find(".dropdown").css("max-height", "0");
                    $(this).parent().find(".dropdown").css("max-height", "100vh");
                    e.preventDefault();
                }
            })
        }
    };
})();
$(function() {

    // Foundation
    $(document).foundation();

    // Call when document is ready
    EMA.BUILD.init();
});